.homeBackground{
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.9)), url("../images/background-size.jpg");
    width: 100%;
    background-size: cover;
}

@media only screen and (min-width: 600px) {
    .myPic{
        justify-self: center;
        width: 60% !important;
    }
}

.introContainer{
    display: flex;
}


@media only screen and (max-width: 600px) {
    .introContainer{
        flex-direction: column-reverse
    }
}