/* slick-dot-styles.css */
.slick-dots{
  bottom: 5px;
  position: relative;
}
  
.slick-dots li.slick-active button:before{
  color: #FF7900;
}

.slick-dots li button:before{
  font-size: 10px;
  color: #FF7900;
}

.slick-prev:before, .slick-next:before{
  color: #FF7900;
}

.slick-dots li{
  width: 14px;
}