@font-face {
    font-family: myFirstFont;
    src: url("../../public/assets/fonts/Morgan.otf");
}

.nav_button{
    filter: invert(30%) sepia(13%) saturate(2684%) hue-rotate(195deg) brightness(95%) contrast(91%);
}


.nav_title {
    font-family: myFirstFont;
    letter-spacing: 3px;
    /* font-size: large; */
}

.bg_show{
    background: linear-gradient(rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.8))
}
